import { $, $$ } from '../helpers'

const successContent = `
    <div class="event-registration__content">
        <div class="event-registration__content__header">
            <h3 class="title">Thank you</h3>

            <p>Your registration request for this event has been sent.</p>
        </div>
    </div>
`

const errorContent = `
    <div class="event-registration__content">
        <div class="event-registration__content__header">
            <h3 class="title">Error</h3>

            <p>An error occured while processing your request.</p>
            <p>Please <a href="javascript:window.location.reload();">click here</a> to try again.</p>
            <p>If the problem persists, please <a href="/contact">contact</a> your regional agency.</p>
        </div>
    </div>
`

const handleSubmit = async (e) => {
    e.preventDefault()

    const form = e.target
    const data = new FormData(form)

    try {
        const response = await fetch('/api/event/v1/register', {
            method: 'POST',
            body: data
        })

        if (!response.ok) {
            throw new Error('HTTP error.')
        }

        const json = await response.json()

        if (!json.success) {
            throw new Error('There was an error submitting the form.')
        }

        form.innerHTML = successContent
    } catch (error) {
        form.innerHTML = errorContent
    }
}

$$('.js-event-register').forEach(form => form.addEventListener('submit', handleSubmit))
