//import { $, $$ } from '../helpers'

function disclosureScript() {
  const toggleButtons = document.querySelectorAll(".js-toggle-disclosure");

  console.log(toggleButtons);
  if (!toggleButtons.length) return;
  toggleButtons.forEach((toggleButton) => {
    if (!toggleButton.classList.contains("js-processed")) {
      toggleButton.addEventListener("click", function (event) {
        const toggleParent = this.closest(".js-toggle-container");
        const toggleTarget = document.querySelector(
          "#" + this.getAttribute("aria-controls")
        );
        const currentState = this.getAttribute("aria-expanded");
        if (currentState === "false")
          this.setAttribute("aria-expanded", "true");
        else this.setAttribute("aria-expanded", "false");
        toggleParent.classList.toggle("inactive");
        toggleTarget.classList.toggle("inactive");
        event.preventDefault();
      });
      toggleButton.classList.add("js-processed");
    }
  });
}

disclosureScript();
