import { $, $$ } from './../helpers/qsa'

const embedSelectors = [
    'iframe[src*="player.vimeo.com"]',
    'iframe[src*="youtube.com"]',
    'iframe[src*="youtube-nocookie.com"]',
    'iframe[src*="dailymotion.com"]'
]

const handleResponsiveEmbed = selector => {
    $$(embedSelectors.join(','), selector || document.body).forEach(el => {
        if ( el.parentNode.classList.contains('responsive-media') ) {
            return
        }

        const responsiveContainer = document.createElement('div')
        responsiveContainer.className = 'responsive-media'
        responsiveContainer.appendChild(el.cloneNode())

        // Replace
        if ( el.parentNode.tagName === 'P' ) {
            el.parentNode.parentNode.replaceChild(responsiveContainer, el.parentNode)
        } else {
            el.parentNode.replaceChild(responsiveContainer, el)
        }
    })
}

handleResponsiveEmbed()
