import { $$ } from './../helpers'
import ResizeObserver from 'resize-observer-polyfill'

const observer = new ResizeObserver(entries => {
    for (const entry of entries) {
        const { width } = entry.contentRect

        entry.target.classList.toggle('contact-card--sm', width < 420)
    }
})

$$('.contact-card').forEach(el => observer.observe(el))
