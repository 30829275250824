import { $, $$ } from '../helpers'

$$('.tabbed-content').forEach(container => {
    const menu = $('.tabbed-content__menu')
    const options = $$('a', menu)
    const targets = $$('.tabbed-content__target', container)

    // Set first tab to active
    options[0].parentNode.classList.add('article-filters__item--active')
    targets.slice(1).forEach(el => el.style.display = 'none')

    // Bind click
    options.forEach(option => {

        option.addEventListener('click', e => {
            e.preventDefault()

            // Get target href
            const target = option.getAttribute('href').substr(1)

            // Hide all targets
            targets.forEach(el => el.style.display = 'none')

            // Show target
            document.getElementById(target).style.display = 'block'

            // Update active class
            $$('.article-filters__item--active', menu).forEach(el => el.classList.remove('article-filters__item--active'))
            option.parentNode.classList.add('article-filters__item--active')
        })

    })
})
