import { $, $$ } from '../helpers'

let boroughOptions = `<form method="post" class="form form--support-redirect">
                    <label for="borough-select" class="visually-hidden">Find your regional agency</label>
                    <div class="custom-select-control">
                        <select name="borough" id="borough-select">
    <option value="false">Select your borough</option>
            <optgroup label="North">
                    <option value="north">Barnet</option>
                    <option value="north">Camden</option>
                    <option value="north">Enfield</option>
                    <option value="north">Hackney</option>
                    <option value="north">Haringey</option>
                    <option value="north">Hertfordshire</option>
                    <option value="north">Islington</option>
                    <option value="north">Waltham Forest</option>
                    <option value="north">Westminster</option>
                </optgroup>
            <optgroup label="East">
                    <option value="east">Barking and Dagenham</option>
                    <option value="east">Essex</option>
                    <option value="east">Havering</option>
                    <option value="east">Kent</option>
                    <option value="east">Newham</option>
                    <option value="east">Tower Hamlets</option>
                    <option value="east">Waltham Forest</option>
                </optgroup>
            <optgroup label="South">
                    <option value="south">Bexley</option>
                    <option value="south">Bromley</option>
                    <option value="south">Croydon</option>
                    <option value="south">Greenwich</option>
                    <option value="south">Kingston</option>
                    <option value="south">Lambeth</option>
                    <option value="south">Lewisham</option>
                    <option value="south">Merton</option>
                    <option value="south">Richmond</option>
                    <option value="south">Southwark</option>
                    <option value="south">Surrey</option>
                    <option value="south">Sutton</option>
                    <option value="south">Wandsworth</option>
                </optgroup>
            <optgroup label="West">
                    <option value="west">Brent</option>
                    <option value="west">Buckinghamshire</option>
                    <option value="west">Ealing</option>
                    <option value="west">Hammersmith and Fulham</option>
                    <option value="west">Hounslow</option>
                    <option value="west">Kensington and Chelsea</option>
                    <option value="west">Westminster</option>
                </optgroup>
    </select>
                    </div>

                    <button type="submit" class="btn">
                        Find your support region
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="btn__icon"><path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z"></path></svg>
                    </button>
                </form>`

if($('.support-redirect')) {

    $('.support-redirect').innerHTML = boroughOptions

    $('.form--support-redirect').addEventListener("submit", function(e) {
        e.preventDefault();
        let select = document.getElementById("borough-select");
        let value = select.options[select.selectedIndex].value;

        window.location.href = `adoption-support/adopt-london-${value}-support/`

    });
}



const toggle = $('.header__menu-toggle')
const header = $('.header')

toggle.addEventListener('click', () => header.classList.toggle('mobile-menu-visible'))
