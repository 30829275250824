import { $, $$ } from '../helpers'


if( $('.adoption-steps') ) {

    console.log('exists')

    const updateNavigation = position => {
        $('.adoption-steps__holder').style.left = `${position}%`
        $('.adoption-steps__controls__progress span.active').classList.remove('active')
        $$('.adoption-steps__controls__progress span')[currentProgess].classList.add('active')
    }

    const updateProgress = () => {
        let progressPercentage  = (currentProgess + 1) * stepPercentage;
        $('.adoption-steps__progress__bar').style.width = `${progressPercentage}%`
    }

    const setup = el => {
        const dot = document.createElement('span')
        $('.adoption-steps__controls__progress').appendChild(dot)
    }

    $$('.adoption-step').forEach(setup)

    const stepsLength = $$('.adoption-step').length
    const stepPercentage = (100 / stepsLength)
    let currentProgess = 0;

    $$('.adoption-steps__controls__progress span')[0].classList.add('active')
    $('.adoption-steps__progress__bar').style.width = `${stepPercentage}%`

    $('.adoption-steps__controls__next').addEventListener('click', () => {
        if(currentProgess === stepsLength - 1) return;
        currentProgess = currentProgess + 1
        let position = currentProgess * -100
        updateNavigation(position)
        updateProgress()
    })

    $('.adoption-steps__controls__prev').addEventListener('click', () => {
        if(currentProgess === 0) return;
        currentProgess = currentProgess - 1
        let position = currentProgess * -100
        updateNavigation(position)
        updateProgress()
    })
}









