import { $, $$ } from '../helpers'

$$('[aria-expanded]').forEach(el => {
    const content = $('[hidden]', el.parentNode)

    el.addEventListener('click', e => {
        const expanded = el.getAttribute('aria-expanded') === 'true' || false

        el.setAttribute('aria-expanded', !expanded)
        content.hidden = expanded
    })
})
